import React, { useEffect, useState } from 'react';
import './LoadingScreen.css';

const LoadingScreen = ({ setLoading }) => {
  const [exit, setExit] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExit(true);
      setTimeout(() => setLoading(false), 500);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [setLoading]);

  return (
    <div className={`loading-screen ${exit ? 'loading-exit' : ''}`}>
      <div className="logo-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="500"
          height="500"
          viewBox="0 0 375 374.999991"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
        >
          <g fill="#edebe7" fillOpacity="1">
            <g transform="translate(137.972835, 227.766936)">
              <g>
                <path
                  className="logo-path"
                  d="M 83.484375 -15.4375 C 84.304688 -12.238281 85.128906 -9.609375 85.953125 -7.546875 C 86.773438 -5.492188 87.90625 -2.976562 89.34375 0 L 77.3125 0 C 77.101562 -3.289062 76.738281 -5.882812 76.21875 -7.78125 C 75.707031 -9.6875 75.039062 -12.238281 74.21875 -15.4375 L 65.578125 -45.515625 L 23.765625 -45.515625 L 14.96875 -14.96875 C 14.144531 -12.082031 13.398438 -9.660156 12.734375 -7.703125 C 12.066406 -5.753906 11.628906 -3.1875 11.421875 0 L 7.71875 0 C 9.257812 -3.1875 10.3125 -5.734375 10.875 -7.640625 C 11.445312 -9.546875 12.144531 -11.9375 12.96875 -14.8125 L 42.28125 -116.65625 C 42.789062 -118.820312 42.632812 -121.085938 41.8125 -123.453125 L 52.625 -123.453125 Z M 24.234375 -47.375 L 65.125 -47.375 L 44.75 -118.359375 Z M 24.234375 -47.375"
                />
              </g>
            </g>
          </g>
          <g fill="#edebe7" fillOpacity="1">
            <g transform="translate(174.296225, 253.801823)">
              <g>
                <path
                  className="logo-path"
                  d="M 8.03125 -71.40625 L 11.15625 -71.40625 C 11.039062 -68.675781 10.9375 -66.398438 10.84375 -64.578125 C 10.757812 -62.765625 10.71875 -61.171875 10.71875 -59.796875 C 10.71875 -58.429688 10.71875 -56.945312 10.71875 -55.34375 L 10.71875 3.921875 C 10.71875 7.078125 10.40625 9.632812 9.78125 11.59375 C 9.15625 13.5625 8.21875 15.15625 6.96875 16.375 C 5.71875 17.59375 4.140625 18.679688 2.234375 19.640625 L 1.78125 18.921875 C 3.445312 17.972656 4.753906 16.929688 5.703125 15.796875 C 6.660156 14.671875 7.363281 13.179688 7.8125 11.328125 C 8.257812 9.484375 8.484375 7.015625 8.484375 3.921875 L 8.484375 -55.34375 C 8.484375 -56.945312 8.484375 -58.429688 8.484375 -59.796875 C 8.484375 -61.171875 8.453125 -62.765625 8.390625 -64.578125 C 8.328125 -66.398438 8.207031 -68.675781 8.03125 -71.40625 Z M 8.03125 -71.40625"
                />
              </g>
            </g>
          </g>
          <g fill="#edebe7" fillOpacity="1">
            <g transform="translate(179.721975, 221.031844)">
              <g>
                <path
                  className="logo-path"
                  d="M -20.296875 -37.796875 L -18.734375 -38.828125 C -17.898438 -37.410156 -17.203125 -36.234375 -16.640625 -35.296875 C -16.085938 -34.359375 -15.585938 -33.546875 -15.140625 -32.859375 C -14.691406 -32.171875 -14.207031 -31.425781 -13.6875 -30.625 L -0.796875 -10.953125 C -0.296875 -10.191406 0.179688 -9.457031 0.640625 -8.75 C 1.097656 -8.050781 1.640625 -7.269531 2.265625 -6.40625 C 2.898438 -5.550781 3.707031 -4.441406 4.6875 -3.078125 L 3.125 -2.046875 C 2.300781 -3.515625 1.609375 -4.703125 1.046875 -5.609375 C 0.484375 -6.515625 -0.0234375 -7.316406 -0.484375 -8.015625 C -0.941406 -8.722656 -1.421875 -9.457031 -1.921875 -10.21875 L -14.8125 -29.890625 C -15.332031 -30.691406 -15.816406 -31.4375 -16.265625 -32.125 C -16.710938 -32.8125 -17.25 -33.597656 -17.875 -34.484375 C -18.5 -35.378906 -19.304688 -36.484375 -20.296875 -37.796875 Z M -20.296875 -37.796875"
                />
              </g>
            </g>
          </g>
          <g fill="#edebe7" fillOpacity="1">
            <g transform="translate(180.983391, 215.310472)">
              <g>
                <path
                  className="logo-path"
                  d="M 23.859375 -36.03125 L 25.515625 -35.125 C 24.671875 -33.71875 23.960938 -32.546875 23.390625 -31.609375 C 22.816406 -30.671875 22.332031 -29.835938 21.9375 -29.109375 C 21.539062 -28.390625 21.109375 -27.601562 20.640625 -26.75 L 9.34375 -5.953125 C 8.894531 -5.128906 8.46875 -4.347656 8.0625 -3.609375 C 7.664062 -2.867188 7.234375 -2.007812 6.765625 -1.03125 C 6.304688 -0.0625 5.707031 1.179688 4.96875 2.703125 L 3.3125 1.796875 C 4.207031 0.367188 4.925781 -0.804688 5.46875 -1.734375 C 6.019531 -2.671875 6.492188 -3.507812 6.890625 -4.25 C 7.296875 -4.988281 7.722656 -5.769531 8.171875 -6.59375 L 19.46875 -27.390625 C 19.9375 -28.242188 20.367188 -29.03125 20.765625 -29.75 C 21.160156 -30.476562 21.601562 -31.332031 22.09375 -32.3125 C 22.582031 -33.289062 23.171875 -34.53125 23.859375 -36.03125 Z M 23.859375 -36.03125"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LoadingScreen;
