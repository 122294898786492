import React, { useState } from 'react';
import Header from './components/Header';
import Hello from './components/Hello';
import LoadingScreen from './components/LoadingScreen';
import About from './components/About';
import Work from './components/Work';
import Projects from './components/Projects';
import Entrepreneurship from './components/Entrepreneurship';
import Volunteer from './components/Volunteer';
import Contact from './components/Contact';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="App">
      {loading ? (
        <LoadingScreen setLoading={setLoading} />
      ) : (
        <>
          <Header/>
          <Hello/>
          <About/>
          <Projects/>
          <Work/>
          <Entrepreneurship/>
          <Volunteer/>
          <Contact/>
        </>
      )}
    </div>
  );
}

export default App;
